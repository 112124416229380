import type { Player as ITF1Player, Config as PlayerConfig, Events } from '@etf1-interne/player'
import type { AdCompanionData, AdCompanionDataEventCallback } from '../types/player'
import { getMediarithmicsObject } from './mediarithmics'
import appConfig from '../config'
import { DEFAULT_SEEK_STEP } from '../constants/player'

export const getPlayerConfig: PlayerConfig = ({
  videoRef,
  consentString,
  noAds,
  tmsPlayerData,
  isLive,
  syndication,
  jwt,
}) => {
  return {
    ...appConfig.playerTF1,
    env: {
      ...appConfig.playerTF1?.env,
      syndication,
    },
    ads: {
      ...appConfig.playerTF1.ads,
      enable: appConfig.playerTF1.ads.enable ? !noAds : false,
      extraParams: {
        _fw_gdpr: 1,
        _fw_gdpr_consent: consentString,
        ...appConfig.playerTF1.metrics.extraParams,
        ...tmsPlayerData.ads,
        ...getMediarithmicsObject(),
      },
    },
    player: {
      ...appConfig.playerTF1.player,
      container: videoRef,
      securityToken: jwt || null,
    },
    controls: {
      seekStep: DEFAULT_SEEK_STEP,
    },
    features: {
      ...appConfig.playerTF1.features,
      comingNext: {
        enabled: true,
      },
    },
    ui: {
      liveIndicator: isLive,
      smallMode: {
        enabled: true,
      },
    },
  }
}

export const allowedPipDirection = (allowedDirection: 'all' | 'down', direction: string) =>
  allowedDirection === 'all' || (allowedDirection === 'down' && direction === allowedDirection)

/**
 * documentation: (http://staging-player.tf1.fr/4.15.0/sample/index.html)
 **/
export const getAllPlayerListeners = (): (keyof Events)[] => {
  return [
    'ads:ad:companion',
    'ads:ad:end',
    'ads:ad:error',
    'ads:ad:pause',
    'ads:ad:resume',
    'ads:ad:skipped',
    'ads:ad:start',
    'ads:slot:end',
    'ads:slot:start',
    'ads:update',
    'ads:ad:clicked',
    'ads:ad:adPauseDisplay',
    'ads:ad:adPauseClick',
    'broadcastads:slot:end',
    'broadcastads:slot:start',
    'buffering:end',
    'buffering:start',
    'ended',
    'error',
    'fullscreenEnter',
    'fullscreenExit',
    'fullscreenEnter:button:click',
    'fullscreenExit:button:click',
    'loading',
    'mediainfo:update',
    'mute',
    'pause',
    'pause:button:click',
    'pause:media:click',
    'play',
    'playing',
    'play:button:click',
    'ready',
    'replay',
    'seeked',
    'unmute',
    'volume',
    'track:button:click',
    'audiotrack:button:click',
    'subtitletrack:button:click',
    'track:audio:changed',
    'track:subtitle:changed',
    'comingNext:nextVideo',
    'track:selection:close',
    'ui:skipmarker:resumeButton:click',
    'ui:skipmarker:titlesButton:click',
    'player:itemchanged',
    'poi:menu:open',
    'poi:item:click',
    'poi:backToLive:click',
    'ctaHD:click',
    'HD:click',
    'chromecast:cast:started',
    'chromecast:cast:ended',
    'chromecast:castButton:click',
  ]
}

export const defaultListenerCallback = () => null

export const companionDataFormatter = (
  data: AdCompanionData,
): {
  backgroundUrl?: string
  backgroundVideoUrl?: string
  companionLinkUrl?: string
  companionType?: string
  clickLinkUrl?: string
  impressionLinkUrl?: string
  player?: ITF1Player
} => {
  if (!data?._content) {
    return {}
  }

  const content = (data?._content && JSON.parse(data._content)) || {}
  const backgroundUrl: string = content.backgroundUrl
  const companionLinkUrl: string = content.companionLink
  const backgroundVideoUrl: string = content.backgroundVideoUrl
  const companionType: string = content.companionType
  const eventCallbacks = data?._eventCallbacks || []
  const impressionLinkUrl: string = eventCallbacks.find(
    (item: AdCompanionDataEventCallback) => item._type === 'IMPRESSION',
  )?._url
  const clickLinkUrl: string = eventCallbacks.find(
    (item: AdCompanionDataEventCallback) => item._type === 'CLICK',
  )?._url

  const player: ITF1Player = data.player

  return {
    backgroundUrl,
    backgroundVideoUrl,
    companionLinkUrl,
    companionType,
    impressionLinkUrl,
    clickLinkUrl,
    player,
  }
}
